@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
    padding: 2px 0;
    width: 0.4em;
    background-color: rgba(33,134,244,0.5);
  }

body::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  body::-webkit-scrollbar-thumb {
    background: rgba(33,134,244,0.5);
  }
  
  /* Handle on hover */
  body::-webkit-scrollbar-thumb:hover {
    background: rgba(33,134,244,0.5);
  }
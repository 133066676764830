.icons{
    display: flex;
}
.icons > * {
    font-size: 28px;
    margin-left: 20px;
}

.logo{
    padding: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.options{
    padding: 10px;
    display: flex;
}

.options > *{
    padding: 10px;
    cursor: pointer;
    color: rgb(17, 81, 255);
}
.option:hover{
    border-bottom: 2px  solid  rgb(22, 131, 255);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 550ms;
}

@media (max-width: 495px) {

    .logo{
        padding: 10px;
        margin: 10px;
        
    }
    
    .options{
        padding: 10px;
        margin: 10px;
        display: flex;
    }
    
    .options > *{
        padding: 10px;
        cursor: pointer;
        color: rgb(17, 81, 255);
        text-align: center;
    }
}